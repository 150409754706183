import {useState} from 'react'
import {Grid, Stack} from '@mui/material'

import {getCurrency} from 'util/model/setting'
import Inventory from './sections/inventory'
import Summary from './sections/summary'
import Breakdown from './sections/breakdown'
import Others from './sections/others'
import Trends from './sections/trends'
import Pendings from './sections/pendings'
import Menu from './sections/menu'

const Dashboard = () => {
  const [category, setCategory] = useState('all')

  const currency = getCurrency('USD')

  return (
    <Stack height="95%">
      <Menu />
      <Stack padding={2} gap={2} overflow="auto">
        <Grid container>
          <Grid item xs={9} padding={0}>
            <Stack spacing={2} marginRight={2} height="100%">
              <Summary currency={currency} />
              <Inventory currency={currency} category={category} setCategory={setCategory} />
            </Stack>
          </Grid>
          <Grid item xs={3} padding={0}>
            <Stack spacing={2}>
              <Others currency={currency} />
              <Breakdown category={category} />
            </Stack>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item padding={0} xs={4}>
            <Stack marginRight={2} height="100%">
              <Pendings />
            </Stack>
          </Grid>
          <Grid item padding={0} xs={8}>
            <Stack>
              <Trends currency={currency} />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  )
}

export default Dashboard
