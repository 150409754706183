import {useMetrics} from 'service/hook'
import {useEffect, useState} from 'react'
import {Line, Bar} from 'react-chartjs-2'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import {Stack, Typography} from '@mui/material'
import AnimatedNumber from 'animated-number-react'

import {useDashboard} from 'stores/useDashboard'
import Button from '../button'
import Container from '../container'
import {COLORS} from '../constants'

dayjs.extend(weekOfYear)

const Inventory = ({currency, category, setCategory}) => {
  const {filter} = useDashboard()
  const {inventory} = useMetrics({
    filter: {...filter.product, ...filter.sold},
    queries: {},
    options: {},
  })

  const [group, setGroup] = useState(localStorage.getItem('dashboard:inventory-group') || 'month')
  const [chart, setChart] = useState(localStorage.getItem('dashboard:inventory-chart') || 'line')

  useEffect(() => {
    const store = {
      group: localStorage.getItem('dashboard:inventory-group'),
      chart: localStorage.getItem('dashboard:inventory-chart'),
    }
    setGroup(store?.group || 'month')
    setChart(store?.chart || 'line')
  }, [])

  const getGroup = ({unlisted, listed}) => {
    const date = {
      current: {
        year: dayjs().year(),
        week: dayjs().week(),
      },
    }

    let products = []

    if (unlisted && listed) {
      switch (category) {
        case 'all':
          products = [...unlisted.entities.products, ...listed.entities.products]
          break
        case 'unlisted':
          products = unlisted.entities.products
          break
        case 'listed':
          products = listed.entities.products
          break
        default:
          products = []
      }
    }

    const grouped = products.reduce(
      (accumulator, product) => {
        const createdAt = dayjs(product.createdAt)

        const isCurrent =
          createdAt.year() < date.current.year ||
          (createdAt.year() === date.current.year && createdAt.week() <= date.current.week)

        if (isCurrent) {
          let point

          switch (group) {
            case 'month':
              point = createdAt.format('MMMM YYYY')
              break
            case 'week':
              point = `Week ${createdAt.week()} ${createdAt.year()}`
              break
            default:
              throw new Error('Invalid group')
          }

          accumulator.all[point] = (accumulator.all[point] || 0) + 1

          if (product.consignId) {
            accumulator.consigned[point] = (accumulator.consigned[point] || 0) + 1
          } else {
            accumulator.owned[point] = (accumulator.owned[point] || 0) + 1
          }
        }

        return accumulator
      },
      {all: {}, consigned: {}, owned: {}},
    )

    const labels = Object.keys(grouped.all).sort((a, b) => {
      const parseLabel = (label) => {
        const [week, year] = label.match(/\d+/g).map(Number)
        return {week, year}
      }

      if (group === 'week') {
        const parsedLabelA = parseLabel(a)
        const parsedLabelB = parseLabel(b)
        if (parsedLabelA.year !== parsedLabelB.year) return parsedLabelA.year - parsedLabelB.year
        return parsedLabelA.week - parsedLabelB.week
      }
      return new Date(b) - new Date(a)
    })

    return {
      labels,
      products: {
        all: labels.map((point) => grouped.all[point]),
        consigned: labels.map((point) => grouped.consigned[point] || 0),
        owned: labels.map((point) => grouped.owned[point] || 0),
      },
    }
  }

  const {labels, products} = getGroup({
    unlisted: inventory.data?.unlisted,
    listed: inventory.data?.listed,
  })

  const data = {
    labels,
    datasets: [
      {
        label: 'All',
        data: products.all,
        borderColor: COLORS.ALL,
        backgroundColor: COLORS.ALL,
        fill: chart === 'bar',
        tension: 0.5,
      },
      {
        label: 'Owned',
        data: products.owned,
        borderColor: COLORS.OWNED,
        backgroundColor: COLORS.OWNED,
        fill: chart === 'bar',
        tension: 0.5,
      },
      {
        label: 'Consigned',
        data: products.consigned,
        borderColor: COLORS.CONSIGNED,
        backgroundColor: COLORS.CONSIGNED,
        fill: chart === 'bar',
        tension: 0.5,
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Inventory',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: group === 'month' ? 'Months' : 'Weeks',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Products',
        },
      },
    },
  }

  let value = {
    unfiltered:
      (inventory.data?.unlisted.unfiltered.total.price || 0) +
        (inventory.data?.listed.unfiltered.total.price || 0) || 0,
    filtered: 0,
  }

  switch (category) {
    case 'all':
      value.filtered =
        (inventory.data?.unlisted.total.price || 0) + (inventory.data?.listed.total.price || 0)
      break
    case 'unlisted':
      value.filtered = inventory.data?.unlisted.total.price || 0
      break
    case 'listed':
      value.filtered = inventory.data?.listed.total.price || 0
  }

  return (
    <Container height="100%">
      <Stack height="100%" justifyContent="space-between">
        <Stack direction="row" justifyContent="space-between" alignItems="start">
          <Stack>
            <Typography fontWeight={600} color={COLORS.PRIMARY}>
              Value of Inventory
            </Typography>
            <Typography fontSize={38} fontWeight={800} color={COLORS.PRIMARY}>
              <AnimatedNumber value={value.unfiltered} formatValue={(v) => currency.format(v || 0)} />
            </Typography>
            <Typography fontWeight={800} color="black">
              <AnimatedNumber value={value.filtered} formatValue={(v) => currency.format(v || 0)} />
            </Typography>
          </Stack>

          <Stack gap={2} justifyContent="end">
            <Stack direction="row" gap={1} justifyContent="end">
              <Button isActive={category === 'all'} onClick={() => setCategory('all')}>
                All
              </Button>
              <Button isActive={category === 'unlisted'} onClick={() => setCategory('unlisted')}>
                Unlisted
              </Button>
              <Button isActive={category === 'listed'} onClick={() => setCategory('listed')}>
                Listed
              </Button>
            </Stack>
          </Stack>
        </Stack>

        {chart === 'line' ? (
          <Line data={data} options={options} />
        ) : (
          <Bar data={data} options={options} />
        )}

        <Stack direction="row" gap={1} alignItems="center" justifyContent="end">
          <Button
            onClick={() => {
              const newChart = chart === 'line' ? 'bar' : 'line'
              setChart(newChart)
              localStorage.setItem('dashboard:inventory-chart', newChart)
            }}
          >
            {chart === 'line' ? 'Line' : 'Bar'}
          </Button>

          <Button
            onClick={() => {
              const newGrouping = group === 'month' ? 'week' : 'month'
              setGroup(newGrouping)
              localStorage.setItem('dashboard:inventory-group', newGrouping)
            }}
          >
            {group === 'month' ? 'Month' : 'Week'}
          </Button>
        </Stack>
      </Stack>
    </Container>
  )
}

export default Inventory
