import React, {useEffect, useMemo, useState} from 'react'
import {Box, Stack, Typography} from '@mui/material'
import {InfoOutlined} from '@mui/icons-material'

import CopytCard from 'components/CopytCard'
import CopytModal from 'components/CopytModal'
import {formatTo} from 'util/date_utils'
import {getDefaultPlatformName, getDefaultLP, getSoldPlatformName, getSold} from 'util/model/listing'
import {useListing} from 'service/hook/use_listing'
import {CopytStyledButton, FieldGroup} from 'views_v2/lib/snippets'
import {CopytBox} from 'views_v2/modules/Inventory/components/cards/InventoryDetails'
import mw from './middleware'
import moment from 'moment'
import {CopytButton} from 'views_v2/lib/snippets'

export const ListingDetails = ({listing, readOnly = false}) => {
  const [isShowConfirm, setIsShowConfirm] = useState(false)
  const [soldDate, setSoldDate] = useState('')

  const {updateSoldDate} = useListing()

  const status = listing.status
  const soldData = useMemo(() => getSold(listing), [listing])
  const processData = useMemo(() => getDefaultLP(listing), [listing])
  const soldPlatformName = useMemo(() => getSoldPlatformName(listing), [listing])
  const processPlatformName = useMemo(() => getDefaultPlatformName(listing), [listing])
  const duration = useMemo(
    () => mw.getDuration(listing?.createdAt, soldData?.createdAt),
    [listing, soldData],
  )

  const processDuration = useMemo(
    () => mw.getDuration(listing?.createdAt, processData?.createdAt),
    [listing, processData],
  )

  const onChangeDate = (e) => {
    const updatedDateTime = moment(e.target.value).set({
      hour: moment().hour(),
      minute: moment().minute(),
      second: moment().second(),
      millisecond: 0,
    })
    const newDate = updatedDateTime.toISOString()
    setSoldDate(newDate)
  }

  useEffect(() => {
    setSoldDate('')
  }, [soldData?.updatedAt])

  return (
    <CopytCard className="consignor-information">
      <CopytCard.Header>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width={1}>
          <CopytCard.Text tag="div">Listing Details</CopytCard.Text>
          <Box
            bgcolor={status == 'sold' ? 'var(--default)' : 'var(--indigo)'}
            color="white"
            width="100px"
            fontSize="12px"
            textAlign="center"
            borderRadius="5px"
          >
            {status == 'sold' ? 'Sold' : 'Processed'}
          </Box>
        </Stack>
      </CopytCard.Header>
      <CopytCard.Body>
        <CopytBox>
          <FieldGroup
            {...{readOnly}}
            sLabel="Platform"
            name="platform"
            value={soldPlatformName || processPlatformName || ''}
          />
          <FieldGroup
            {...{readOnly}}
            sLabel="Listing Duration"
            name="listingDuration"
            value={soldData ? duration : processDuration}
          />
        </CopytBox>

        <CopytBox>
          <FieldGroup
            {...{readOnly}}
            sLabel="Sell Price"
            name="sellPrice"
            value={Number(soldData?.price).toFixed(2) || Number(processData?.price).toFixed(2) || ''}
          />
          <FieldGroup
            {...{readOnly}}
            sLabel="Shipping Cost"
            name="shippingCost"
            value={Number(soldData?.shippingFee).toFixed(2) || ''}
          />
        </CopytBox>
        <CopytBox>
          <FieldGroup
            {...{readOnly}}
            sLabel="Return"
            name="return"
            value={soldData?.actualReturn || ''}
            style={{width: status === 'sold' ? '100%' : '50%'}}
          />
          {status === 'sold' && (
            <FieldGroup
              sLabel="Sold Date"
              type="date"
              value={
                soldDate ? formatTo(soldDate, 'YYYY-MM-DD') : formatTo(soldData?.updatedAt, 'YYYY-MM-DD')
              }
              onChange={onChangeDate}
            />
          )}
        </CopytBox>

        {soldDate && (
          <Stack direction="flex" gap={1}>
            <CopytButton
              variant="outlined"
              sx={{marginBottom: '10px'}}
              onClick={() => {
                setSoldDate('')
              }}
            >
              Cancel
            </CopytButton>
            <CopytButton
              variant="contained"
              sx={{marginBottom: '10px'}}
              onClick={() => {
                setIsShowConfirm(true)
              }}
            >
              Save
            </CopytButton>
          </Stack>
        )}
      </CopytCard.Body>

      <CopytModal
        className="no-padding"
        closeButton={{hidden: true}}
        show={isShowConfirm}
        size="sm"
        toggle={false}
      >
        <Box>
          <Box className="modal-title">
            <Box className="modal__heading" mx="0px !important">
              <Typography variant="h1" textAlign="left">
                Confirm Action
              </Typography>
            </Box>
          </Box>
          <Box className="modal-body" padding={0} mb={2}>
            <Stack p={2} gap={1.5} direction="row" alignItems="center">
              <InfoOutlined sx={{height: '50px', fontSize: '2rem'}} />
              <Stack>
                <Typography lineHeight={1.2}>Are you sure you want to change the sold date</Typography>
                <Typography sx={{fontWeight: '600'}} lineHeight={1.2} textAlign="center">
                  {`from ${formatTo(soldData?.updatedAt, 'MMM DD')} to ${formatTo(soldDate, 'MMM DD')}?`}
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction="row"
              justifyContent="flex-end"
              pr={2}
              pt={1.5}
              borderTop="1px solid var(--lighter)"
              spacing={2}
            >
              <CopytStyledButton
                variant="outline"
                color="primary"
                onClick={() => {
                  setSoldDate('')
                  setIsShowConfirm(false)
                }}
              >
                Cancel
              </CopytStyledButton>
              <CopytStyledButton
                variant="contained"
                color="primary"
                sx={{width: '150px'}}
                onClick={() => {
                  updateSoldDate({id: soldData?.id, value: {date: soldDate}})
                  setTimeout(() => {
                    setIsShowConfirm(!isShowConfirm)
                  }, 1000)
                }}
              >
                Yes, Proceed
              </CopytStyledButton>
            </Stack>
          </Box>
        </Box>
      </CopytModal>
    </CopytCard>
  )
}

export default ListingDetails
