import {useAuth0} from '@auth0/auth0-react'
import {captureException} from '@sentry/browser'
import {useQuery} from 'react-query'
import axios from 'axios'

import {paths} from 'constant/api'
import {createRequestOptions} from 'util/network'

const getInventory = async ({token, filter, queries}) => {
  try {
    const {
      data: {data},
    } = await axios.post(`${paths.metrics}/inventory`, {filter}, createRequestOptions(token))

    return data
  } catch (error) {
    captureException(error)
    throw new Error(error)
  }
}

const getSales = async ({token, filter, queries}) => {
  try {
    const {
      data: {data},
    } = await axios.post(`${paths.metrics}/sales`, {filter}, createRequestOptions(token))

    return data
  } catch (error) {
    captureException(error)
    throw new Error(error)
  }
}

const getTrends = async ({token, filter, queries}) => {
  try {
    const {
      data: {data},
    } = await axios.post(`${paths.metrics}/trends`, {filter}, createRequestOptions(token))

    return data
  } catch (error) {
    captureException(error)
    throw new Error(error)
  }
}

const getPendings = async ({token, filter, queries}) => {
  try {
    const {
      data: {data},
    } = await axios.post(`${paths.metrics}/pendings`, {filter}, createRequestOptions(token))

    return data
  } catch (error) {
    captureException(error)
    throw new Error(error)
  }
}

const useMetrics = ({filter = {}, queries = {}, options = {}}) => {
  const {getAccessTokenSilently} = useAuth0()

  const inventory = useQuery(
    ['metrics:inventory', filter, queries],
    async () => {
      const token = await getAccessTokenSilently()
      return await getInventory({token, filter, queries})
    },
    {
      ...options,
      refetchInterval: 60_000,
      keepPreviousData: true,
    },
  )

  const sales = useQuery(
    ['metrics:sales', filter, queries],
    async () => {
      const token = await getAccessTokenSilently()
      return await getSales({token, filter, queries})
    },
    {
      ...options,
      refetchInterval: 30_000,
      keepPreviousData: true,
    },
  )

  const trends = useQuery(
    ['metrics:trends', filter, queries],
    async () => {
      const token = await getAccessTokenSilently()
      return await getTrends({token, filter, queries})
    },
    {
      ...options,
      keepPreviousData: true,
    },
  )

  const pendings = useQuery(
    ['metrics:pendings', filter, queries],
    async () => {
      const token = await getAccessTokenSilently()
      return await getPendings({token, filter, queries})
    },
    {
      ...options,
      keepPreviousData: true,
    },
  )

  return {
    inventory,
    sales,
    trends,
    pendings,
  }
}

export default useMetrics
