import {LinearProgress, Stack, Typography} from '@mui/material'

import {useDashboard} from 'stores/useDashboard'
import Container from '../container'
import {useMetrics} from 'service/hook'
import {COLORS} from '../constants'

const Others = ({currency}) => {
  const {filter} = useDashboard()
  const {inventory, sales} = useMetrics({
    filter: {
      ...filter.product,
      ...filter.sold,
    },
    queries: {},
    options: {},
  })

  const count = {
    products: sales.data?.count + inventory.data?.unlisted.count.all + inventory.data?.listed.count.all,
    sold: sales.data?.count,
  }

  return (
    <Container>
      <Stack spacing={2}>
        <Typography fontWeight={800} fontSize={20} color="black">
          Other KPIs
        </Typography>

        <Stack spacing={1}>
          <Typography fontWeight={600} color="black" marginBottom={0.5}>
            Average
          </Typography>

          <Stack spacing={0.5}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography fontSize={14} color="black">
                Gross Margin:
              </Typography>
              <Typography fontSize={14} color="black">
                {sales.data?.average.grossMargin.toFixed(2) || 0}%
              </Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography fontSize={14} color="black">
                Transaction Value:
              </Typography>
              <Typography fontSize={14} color="black">
                {currency.format(sales.data?.average.sellPrice || 0)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={1}>
          <Stack>
            <Typography fontWeight={600} color="black">
              Inventory Sell Through Rate
            </Typography>
            <Typography fontSize={12} color="black">
              Sold through{' '}
              {count.sold && count.products
                ? `${((count.sold / count.products) * 100).toFixed(2)}%`
                : '0%'}{' '}
              of beginning inventory
            </Typography>
          </Stack>

          <Stack spacing={0.5} overflow="hidden">
            <LinearProgress
              value={(count.sold / count.products) * 100 || 0}
              variant="determinate"
              sx={{
                height: 8,
                borderRadius: 4,
                backgroundColor: '#f2f2f2',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: COLORS.PRIMARY,
                  borderRadius: 4,
                },
              }}
            />

            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography fontSize={14} color="black">
                {count.sold || 0} / {count.products || 0}
              </Typography>
              <Typography fontSize={14} color="black">
                {count.sold && count.products
                  ? `${((count.sold / count.products) * 100).toFixed(2)}%`
                  : '0%'}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  )
}

export default Others
